<template>
	<v-app style="background-color:#f8f8f8;">
		<v-app-bar app color="#fff" dense>
			<v-app-bar-nav-icon small v-if="!drawer" @click="drawer = true;"></v-app-bar-nav-icon>
			<v-spacer></v-spacer>
			<img src="/icon/logo.svg" @click="$store.commit('debug');" height="20" class="ml-1" />
			<v-spacer></v-spacer>
			{{ $store.state.principal.email }}
			<v-btn small icon @click="logout" class="ml-2">
				<v-icon color="#2D2926">mdi-exit-to-app</v-icon>
			</v-btn>
		</v-app-bar>
		<v-navigation-drawer app v-model="drawer">
			<v-list nav dense>
				<v-list-item link>
					<v-list-item-icon @click="drawer = false;">
						<v-icon color="#2D2926">mdi-close</v-icon>
					</v-list-item-icon>
					<v-list-item-content></v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="secondary">mdi-account-circle</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							<strong>{{ $store.state.principal.name }}</strong>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="currentPassword = newPassword = confirmNewPassword = null; showEditPassword = true;"
					link>
					<v-list-item-icon>
						<v-icon color="secondary">mdi-lock-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Change password</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list nav dense>
				<v-list-item link to="/administration/dashboard"
					v-if="['super_user', 'admin'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-monitor-dashboard</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Dashboard</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/administration/trainingModule"
					v-if="['super_user'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-account-reactivate-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Training module</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/administration/bdm"
					v-if="['super_user', 'admin'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-account-supervisor</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>BDM</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/administration/agent/info">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-account-supervisor</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-if="['bdm'].includes($store.state.principal.role)">My duo
							agents</v-list-item-title>
						<v-list-item-title v-else>Agent Info</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/administration/agency">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-face-agent</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Agency</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/administration/sales">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-card-bulleted</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-if="['bdm'].includes($store.state.principal.role)">My duo
							agents</v-list-item-title>
						<v-list-item-title v-else>Sales Info</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/administration/notification"
					v-if="['super_user'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-bell</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Notification</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-group>
					<template v-slot:activator>
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item 
							v-if="['super_user', 'admin'].includes($store.state.principal.role)">
							<router-link to="/administration/report" style="text-decoration: none; color: black; margin-left: -8px;">
								<v-list-item-content>
									<v-list-item-title>Report</v-list-item-title>
								</v-list-item-content>
							</router-link>
						</v-list-item>
					</template>
					
					<v-list-item link to="/administration/toolkit"
						v-if="['super_user', 'admin'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Toolkit Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/administration/sales/report"
						v-if="['super_user'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Sales Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/administration/destinationDash"
						v-if="['super_user', 'admin'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Destination Dash<br />Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/administration/christmasChase"
						v-if="['super_user', 'admin'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Christmas Chase<br />Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/administration/scoreSpot"
						v-if="['super_user', 'admin'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>FCTG Incentive<br />Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/administration/visitbdm"
						v-if="['super_user', 'admin'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Visit BDM Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/administration/creator/accesslog"
						v-if="['super_user', 'admin'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Content Creator<br />Access Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/administration/form"
						v-if="['super_user', 'admin'].includes($store.state.principal.role)">
						<v-list-item-icon>
							<v-icon color="secondary">mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Form Report</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list>
			<v-divider></v-divider>
			<v-list nav dense>
				<v-list-item link to="/administration/creator/tile/dashboard"
					v-if="['super_user', 'admin'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-view-dashboard-edit</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Homepage Creator</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list nav dense>
				<v-list-item link to="/administration/creator/tile/training"
					v-if="['super_user', 'admin'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-view-dashboard-edit-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Training Creator</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list nav dense>
				<v-list-item link to="/administration/creator/page/landing"
					v-if="['super_user', 'admin'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-monitor-dashboard</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Landing Page Creator</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list nav dense>
				<v-list-item link to="/administration/creator/form"
					v-if="['super_user', 'admin'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-poll</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Dynamic Form</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list nav dense>
				<v-list-item link to="/administration/creator/tile/video"
					v-if="['super_user', 'admin'].includes($store.state.principal.role)">
					<v-list-item-icon>
						<v-icon color="secondary">mdi-video</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Video Creator</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>

		<router-view :key="$route.fullPath"></router-view>

		<v-dialog v-model="showEditPassword" max-width="500">
			<v-card style="padding-bottom:10px;">
				<v-card-title>Change password</v-card-title>
				<v-form ref="formEditPassword" class="px-5">
					<v-text-field v-model="currentPassword" label="Current password" type="password"></v-text-field>
					<v-text-field v-model="newPassword" label="New password" type="password"></v-text-field>
					<v-text-field v-model="confirmNewPassword" label="Confirm new password" type="password"></v-text-field>
				</v-form>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="showEditPassword = false">Cancel</v-btn>
					<v-btn @click="editPassword" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="$store.state.notification.show" max-width="500">
			<v-card style="padding:20px;">
				<div style="display:flex;justify-content:space-between;">
					<h2 style="margin-bottom:10px;">{{ $store.state.notification.title }}</h2>
					<v-btn icon color="secondary" @click="hideNotification">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<template
					v-if="$store.state.notification.content && $store.state.notification.content.type === 'NOTIFICATION'">
					<div style="color:#C42126;font-size:14px;" :key="item"
						v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
					<div style="color:#ED5025;font-size:14px;" :key="item"
						v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
					<div style="color:#E26026;font-size:14px;" :key="item"
						v-for="item in $store.state.notification.content.noticeList">{{ item }}</div>
				</template>
				<template v-else>
					<div style="color:#E26026;font-size:14px;">{{ $store.state.notification.content }}</div>
				</template>
				<div style="text-align:center;margin-top:20px;">
					<v-btn color="primary" @click="hideNotification">Close</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<img v-show="$root.loader.value" src="loader.svg" class="loader" />
	</v-app>
</template>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

a:hover
	text-decoration: none

.v-list-group__header.v-list-item
	padding: 0

.v-list-group__header__append-icon
	position: absolute
	right: 15px

.v-list-group__header__append-icon > .v-icon
	color: white

.v-list-group__header.v-list-item.v-list-item--active
	background-color: white !important
	text-decoration: none !important

.v-list-group__header.v-list-item.v-list-item--active > .v-list-item > .v-list-item__icon
	background-color: white !important
	min-width: 56px
	height: 56px
	padding-right: 15px !important
	margin: 0 13px 0 auto !important

.v-list-group__items
	background-color: white !important
	text-decoration: none !important

.v-list-item__icon
	background-color: white !important
	text-decoration: none !important

.v-list-group__items > .v-list-item > .v-list-item__icon
	background-color: white !important
	min-width: 56px
	height: 56px
	padding-right: 15px !important
	margin: 0 0 0 15px !important

.v-list-group__items:hover > .v-list-item:hover > .v-list-item__icon,
.v-list-group__items > .v-list-item--active.v-list-item > .v-list-item__icon
	background-color: white !important
</style>

<script>
import axios from "axios";

export default {
	data: function () {
		return {
			drawer: true,
			showEditPassword: false,
			currentPassword: null,
			newPassword: null,
			confirmNewPassword: null
		};
	},
	methods: {
		editPassword() {
			axios
				.post("/api/password/edit", {
					currentPassword: this.currentPassword,
					newPassword: this.newPassword,
					confirmNewPassword: this.confirmNewPassword
				})
				.then(() => {
					this.$refs.formEditPassword.reset();
					this.showEditPassword = false;
				})
				.catch(() => { });
		},
		logout() {
			this.$store
				.dispatch("logoutAdministration")
				.then(() => {
					this.$router.push("/administration/login");
				})
				.catch(() => { });
		},
		hideNotification() {
			if (this.$store.state.notification.title === "Unauthorized") {
				this.$store.commit("hideNotification");
				this.$router.push("/");
			} else {
				this.$store.commit("hideNotification");
			}
		}
	}
};
</script>