<template>
	<v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
		<subHeader />

		<template v-if="page.type">
			<component ref="trainingModule" :is="'page-' + page.type" v-bind:page="page" v-bind:isPreview="false" v-on:previousPage="previousPage()" v-on:nextPage="nextPage()" v-on:submit="submit"></component>
		</template>

		<v-dialog v-model="dialogFinish" max-width="400">
			<v-card class="mx-auto" max-width="400">
				<v-toolbar flat color="#221551" dark>
					<v-toolbar-title>Finish</v-toolbar-title>
				</v-toolbar>

				<v-card-text>
					<h2 class="text-h6 my-4">Your Score: {{ resultScore }}</h2>
				</v-card-text>

				<v-card-actions class="pa-4">
					<v-spacer></v-spacer>
					<v-btn @click="finishTraining" color="primary">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>

	<duo-training-module-page-mobile v-else></duo-training-module-page-mobile>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from "axios";
import subHeader from "./subHeader.vue";
import pageStart from "./trainingModulePageStart";
import pageStart2 from "./trainingModulePageStart2";
import pageStart3 from "./trainingModulePageStart3";
import pageInformationImage1 from "./trainingModulePageInformationImage1";
import pageInformationImage2 from "./trainingModulePageInformationImage2";
import pageInformationImage3 from "./trainingModulePageInformationImage3";
import pageInformationText1 from "./trainingModulePageInformationText1";
import pageInformationText2 from "./trainingModulePageInformationText2";
import pageInformationVideo from "./trainingModulePageInformationVideo";
import pageMultipleChoice from "./trainingModulePageMultipleChoice.vue";
import pageShortAnswer from "./trainingModulePageShortAnswer.vue";
import pageFreeAnswer from "./trainingModulePageFreeAnswer.vue";
import duoTrainingModulePageMobile from "./mobile/duoTrainingModulePageMobile.vue"

export default {
	components: {
		subHeader,
		// page-{page.type}
		"page-start": pageStart,
		"page-start-1": pageStart, //discrepancy between old data intentional duplicate, do not remove
		"page-start-2": pageStart2,
		"page-start-3": pageStart3,
		"page-info-image-1": pageInformationImage1,
		"page-info-image-2": pageInformationImage1,
		"page-info-image-3": pageInformationImage2,
		"page-info-image-4": pageInformationImage2,
		"page-info-image-5": pageInformationImage3,
		"page-info-text-1": pageInformationText1,
		"page-info-text-2": pageInformationText2,
		"page-info-video-1": pageInformationVideo,
		"page-info-video-2": pageInformationVideo,
		"page-multiple-choice-1": pageMultipleChoice,
		"page-multiple-choice-2": pageMultipleChoice,
		"page-short-answer": pageShortAnswer,
		"page-free-answer": pageFreeAnswer,
		duoTrainingModulePageMobile
	},
	data() {
		return {
			dialogFinish: false,
			moduleId: 0,
			pageIndex: 0,
			pageList: [],
			page: {},
			scores: {
				moduleId: Number(this.$route.params.moduleId),
				attempt: 1,
				finalScore: 0,
				map: {
					pageScore: []
				}
			},
			resultScore: 0,
			result: false
		};
	},
	created() {
		this.moduleId = this.$route.params.moduleId;
	},
	mounted() {
		this.listPageByModuleId();
	},
	beforeMount() {
		window.addEventListener("beforeunload", function (e) {
			// Cancel the event
			e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
			// Chrome requires returnValue to be set
			e.returnValue = "";
		});
		this.$once("hook:beforeDestroy", () => {
			window.removeEventListener("beforeunload", this.preventNav);
		});
	},
	methods: {
		listPageByModuleId() {
			axios
				.get("/system/page/module/list?moduleId=" + this.moduleId)
				.then((response) => {
					this.pageList = response.data.sort(this.compareOrder);
					this.loadPage();
				})
				.catch(() => {});
		},
		compareOrder(previousPage, nextPage) {
			if (previousPage.sortOrder < nextPage.sortOrder) return -1;
			if (previousPage.sortOrder > nextPage.sortOrder) return 1;
			return 0;
		},
		loadPage() {
			if (this.pageList.length > 0) {
				this.page = this.pageList[this.pageIndex];
				let types = ["multiple-choice-1", "multiple-choice-2", "short-answer", "free-answer"];
				if (types.some((item) => this.page.type.includes(item)) && !this.$store.state.retakeTrainingModule) {
					this.fetchAnswer();
				}
			}
		},
		previousPage() {
			if (this.pageIndex > 0) {
				this.pageIndex = this.pageIndex - 1;
				this.loadPage();
			}
		},
		nextPage() {
			if (this.pageIndex < this.pageList.length - 1) {
				this.pageIndex = this.pageIndex + 1;
				this.loadPage();
			} else {
				this.dialogFinish = true;
			}
		},
		fetchAnswer() {
			axios
				.get("/system/score/answer?moduleId=" + this.page.moduleId)
				.then((response) => {
					if (response.data != null) {
						this.$refs.trainingModule.fetchAnswer(response);
						// this.scores.push(response.data);
						let score = response.data;
						let pageScore = score.map.pageScore.find(score => Number(score.pageId) === this.page.id);
						this.resultScore += pageScore.points;
					}
				})
				.catch(() => {});
		},
		submit() {
			this.page = this.pageList[this.pageIndex];

			if (this.page.name.includes("multiple-choice")) {
				let options = this.page.valueMap.bodyQuestionMultipleChoice.optionList;
				let answers = this.page.valueMap.responseOptionId;

				let responseOptions = options.filter((item) => answers.includes(item.id));
				let totalPoints = responseOptions.map((item) => parseInt(item.points)).reduce((prev, next) => prev + next);

				this.scores.map.pageScore.push({
					pageId: this.page.id.toFixed(0),
					pageName: this.page.name,
					answer: JSON.stringify(this.page.valueMap.responseOptionId),
					points: totalPoints
				});
			} else {
				// short / free answer
				let responseText = this.page.valueMap.responseText;

				if(this.page.name.includes("short-answer")){
					let correctAnswerText = this.page.valueMap.bodyQuestionShortAnswer.correctAnswerText;
					let regex = this.page.valueMap.bodyQuestionShortAnswer.regex;
					let caseSensitive = this.page.valueMap.bodyQuestionShortAnswer.caseSensitive;
					const pattern = new RegExp("\\b" + correctAnswerText + "\\b", caseSensitive ? "" : "i");

					if (regex) {
						this.result = responseText.match(pattern) == null ? false : true;
					} else {
						this.result = caseSensitive ? responseText == correctAnswerText : responseText.toLowerCase() === correctAnswerText.toLowerCase();
					}
				} else {
					this.result = false;
				}

				this.scores.map.pageScore.push({
					pageId: this.page.id.toFixed(0),
					pageName: this.page.name,
					answer: responseText,
					points: this.result ? 100 : 0
				});
			}

			this.scores.finalScore = this.scores.map.pageScore.map((item) => parseInt(item.points)).reduce((prev, next) => prev + next);
			this.resultScore = this.scores.finalScore;
			this.save();
		},
		save() {
			this.scores.resuming = this.$store.state.retakeTrainingModule == false;
			axios
				.post("/system/score/add", { score: this.scores })
				.then((response) => {
					this.scores.id = response.data.id;
					this.resultScore = Math.floor(response.data.finalScore);
				})
				.catch(() => {});
		},
		exitTraining() {
			this.$router.push({ path: "/training" });
		},
		finishTraining() {
			// For saving modules without questions
			if(this.scores.map.pageScore == 0){
				this.save();
			}
			this.$router.push({ path: "/training" });
		}
	}
};
</script>