<template>
	<v-main>
		<div style="background-color: #fff; padding: 30px">
			<v-row class="d-flex justify-space-between align-center">
				<v-col class="d-flex" cols="12" md="8">
					<h3>Training module configuration of {{ trainingModule.name }} ({{ trainingModule.id }})</h3>
				</v-col>
				<v-col class="d-flex" cols="12" md="4">
					<div class="ml-auto">
						<v-btn fab elevation="2" small @click="showAddPage" class="mr-4">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
						<v-btn color="primary" depressed elevation="2" large :disabled="pageList.length == 0" @click="savePage">
							<v-icon dark>mdi-content-save-outline</v-icon>&nbsp;Save
						</v-btn>
					</div>
				</v-col>
			</v-row>
			<br />
			<br />

			<draggable v-if="isLoaded" v-bind="dragOptions" v-model="pageList" class="row">
				<v-card flat rounded="0" height="260" class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(page, index) in pageList" :key="index+0">
					<v-card-title class="my-0 py-0" style="background: #00C0F3; color:white; height: 40px; cursor: move;">
						<div v-for="pageType in pageTypeStaticData" :key="pageType.type">
							{{validateTitle(page, pageType, index)}}
						</div> 
					</v-card-title>
					<v-card-text class="ma-0 pa-0" style="position: relative; cursor: move;">
						<div v-for="pageType in pageTypeStaticData" :key="pageType.type">
							<v-img v-if="page.type === pageType.type" :src="pageType.srcImage" contain eager></v-img>
						</div>
						<v-btn fab bottom right absolute x-small elevation="1" style="right: 40px;" @click="$router.push('/administration/trainingModule/' + moduleId + '/page/' + page.id + '/configuration')">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn fab bottom right absolute x-small elevation="1" style="right: 0px;" @click="showDeletePage(page)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</v-card-text>
				</v-card>
			</draggable>

			<v-row v-else>
				<v-col height="260" class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="n in 8" :key="n">
					<v-skeleton-loader class="mx-auto" type="list-item-two-line, image"></v-skeleton-loader>
				</v-col>
			</v-row>
		</div>

		<v-dialog v-model="dialogAdd" fullscreen>
			<v-card>
				<v-card-title>
					<span class="headline">Add {{ title }}</span>
					<v-spacer></v-spacer>
					<v-btn icon x-large @click="dialogAdd = false"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-card-text>
					<h4>Choose a page template</h4>
					<br />
					<v-row>
						<v-card flat rounded="0" height="300" class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="pageType in pageTypeStaticData" :key="pageType.type" @click="addPage(pageType.type)">
							<v-card-title class="my-0 py-0" style="background:#00C0F3; color:white; height: 40px;">{{ pageType.title }}</v-card-title>
							<v-card-text class="ma-0 pa-0" style="position: relative;">
								<v-img :src="pageType.srcImage" contain eager></v-img>
							</v-card-text>
						</v-card>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Delete {{ title }}</span>
				</v-card-title>
				<v-card-text>Are you sure you want to delete '{{ page.name }}' ({{ page.id }})?</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="dialogDelete = false">Cancel</v-btn>
					<v-btn @click="deletePage" color="accent">Delete</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-main>
</template>

<style scoped >
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
</style>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
import draggable from "vuedraggable";

export default {
	components: {
		draggable
	},
	props: ["moduleId"],
	data: function() {
		return {
			title: "page",
			trainingModule: {},
			page: {},
			pageList: [],
			pageTypeStaticData: [
				{ type: "start-1", title: "Start 1 Page", srcImage: "/template/anz-training-start-1.jpg" },
				{ type: "start-2", title: "Start 2 Page", srcImage: "/template/anz-training-start-2.jpg" },
				{ type: "start-3", title: "Start 3 Page", srcImage: "/template/anz-training-start-3.jpg" },
				{ type: "info-image-1", title: "Information Image 1 Page", srcImage: "/template/anz-training-info-image-1.jpg" },
				{ type: "info-image-2", title: "Information Image 2 Page", srcImage: "/template/anz-training-info-image-2.jpg" },
				{ type: "info-image-3", title: "Information Image 3 Page", srcImage: "/template/anz-training-info-image-3.jpg" },
				{ type: "info-image-4", title: "Information Image 4 Page", srcImage: "/template/anz-training-info-image-4.jpg" },
				{ type: "info-image-5", title: "Information Image Full Page", srcImage: "/template/anz-training-info-image-5.jpg" },
				{ type: "info-video-1", title: "Information Video 1 Page", srcImage: "/template/anz-training-info-video-1.jpg" },
				{ type: "info-video-2", title: "Information Video 2 Page", srcImage: "/template/anz-training-info-video-2.jpg" },
				{ type: "info-text-1", title: "Information Text 1 Page", srcImage: "/template/anz-training-info-text-1.jpg" },
				{ type: "info-text-2", title: "Information Text 2 Page", srcImage: "/template/anz-training-info-text-2.jpg" },
				{ type: "multiple-choice-1", title: "Multiple Choice 1 Page", srcImage: "/template/anz-training-question-multiple-choice-1.jpg" },
				{ type: "multiple-choice-2", title: "Multiple Choice 2 Page", srcImage: "/template/anz-training-question-multiple-choice-2.jpg" },
				{ type: "short-answer", title: "Short Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg" },
				{ type: "free-answer", title: "Free Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg" },
			],
			dialogAdd: false,
			dialogDelete: false,
		};
	},
	mounted: function() {
		this.findTrainingModule();
		this.listPage();
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},
		isLoaded() {
			return !this.$root.isBlank(this.trainingModule);
		}
	},
	methods: {
		reorderPage() {
			/* eslint-disable no-console */
			let index = 0;
			for (const item of this.pageList) {
				index++;
				item.sortOrder = 1000 + index;
			}
		},
		savePage() {
			this.reorderPage();
			axios
				.post("/api/page/order/edit", { pages: this.pageList })
				.then()
				.catch(() => {});
		},
		findTrainingModule() {
			axios
				.get("/api/trainingModule?id=" + this.moduleId)
				.then(response => {
					this.trainingModule = response.data;
				})
				.catch(() => {});
		},
		listPage() {
			axios
				.get("/api/page/module/list?moduleId=" + this.moduleId)
				.then(response => {
					this.pageList = response.data;
				})
				.catch(() => {});
		},
		showAddPage() {
			this.page = {};
			this.dialogAdd = true;
		},
		addPage(type) {
			axios
				.post("/api/page/add?page.moduleId=" + this.moduleId, { page: { type: type, name: type } })
				.then(response => {
					this.dialogAdd = false;
					this.listPage();
				})
				.catch(() => {});
		},
		showDeletePage(item) {
			this.page = this.$root.clone(item);
			this.dialogDelete = true;
		},
		deletePage() {
			axios
				.post("/api/page/delete?id=" + this.page.id, {})
				.then(() => {
					this.dialogDelete = false;
					this.listPage();
				})
				.catch(() => {});
		},
		validateTitle(data, pageType, index){
			var title = pageType.title;
			var header = data.valueMap.headerTitle;
			if(data.type != pageType.type){
              return "";
			}
			if (header){
				var div = document.createElement("div");
				div.innerHTML = header;
				title = div.textContent || div.innerText || "";
			}  
            return (index + 1) + ". " + title;
		}
	}
};
</script>