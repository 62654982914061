import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#009EC1",
				secondary: "#00C0F3",
				// accent: "#8F257A",
				accent: "#440099",
				error: "#ec040f",
				info: "#00C0EF",
				success: "#00A65A",
				warning: "#F39C12",
				duoPrimary: "#2D2926",
				duoSecondary: "#04A0A8",
				duoTertiary: "#440099",
				duoQuaternary: "#221551"
			}
		}
	}
});
