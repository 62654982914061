<template>
	<v-main>
		<div :style="(this.isBackgroundImage ? 'background-image: url(\'' + this.$bucket + '/image/background/MySales.BG.jpg\'); background-size: cover; position: relative;' : 'background-color: #F8F8F8;') + 'padding: 30px;'">
			<v-row no-gutters class="justify-space-between align-center">
				<h2>Page configuration</h2>
				<div>
					<v-btn large depressed color="rounded-0 accent" @click="closePageConfiguration()">Cancel</v-btn>
					<v-btn large depressed class="rounded-0 ml-3" color="secondary" @click="showPreview(page)">Preview</v-btn>
					<v-btn large depressed class="rounded-0 ml-3" color="primary" @click="save">Save</v-btn>
				</div>
			</v-row>
			
			<div>
				<v-col v-if="isLoaded" cols="12">
					<!-- Change background to image -->
					<v-col cols="12" class="d-flex justify-end align-center">
						<div>
							<v-checkbox v-model="isBackgroundImage" @change="refresh" label="Use image background" color="success" hide-details class="mt-0"></v-checkbox>
						</div>
					</v-col>
					<!-- Default Image if empty -->
					<template v-if="!page.valueMap.headerTitle && !page.valueMap.bodySummary">
						<div v-for="pageType in pageTypeStaticData" :key="pageType.type">
							<v-img v-if="page.type === pageType.type" :src="pageType.srcImage" width="auto" height="400" contain></v-img>
						</div>
					</template>
					<!-- Page Config -->
					<!-- don't mutate 'page' when doing test to the question (preview mode), so clone it -->
					<!-- :key used to reset question component after close preview dialog -->
					<component :is="'page-'+page.type" v-bind:page="pageClone" v-bind:isPreview="true" :key="questionDialogKey"></component>
				</v-col>
				<!-- Skeleton loader -->
				<v-row v-else class="pt-12 mx-12">
					<v-col height="260" class="col-12" v-for="n in 2" :key="n">
						<v-skeleton-loader class="mx-auto" type="list-item-two-line, image"></v-skeleton-loader>
					</v-col>
				</v-row>
				<div class="col-12"></div>
				<!-- Form Builder -->
				<v-col cols="12">
					<form-builder :pageId="pageId" :page="page" :fields="fields" :attachments="attachments"></form-builder>
				</v-col>
			</div>
		</div>

		<v-dialog v-model="dialogPreview" persistent no-click-animation fullscreen hide-overlay scrollable transition="dialog-bottom-transition" @keydown.esc="closePreview()">
			<v-card height="100%" width="100%" rounded="0">
				<v-card-text class="fill-height pa-0 ma-0" :style="this.isBackgroundImage ? 'background-image: url(\'' + this.$bucket + '/image/background/MySales.BG.jpg\'); background-size: cover; position: relative;' : 'background-color: #F8F8F8;'">
					<v-row class="d-flex pt-3 pr-3">
						<v-col cols="12" class="d-flex justify-end">
							<v-btn icon @click="closePreview()">
								<v-icon large>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<br />
					<br />
					<!-- don't mutate 'page' when doing test to the question (preview mode), so clone it -->
					<!-- :key used to reset question component after close preview dialog -->
					<v-container fluid>
						<component :is="'page-'+page.type" v-bind:page="pageClone" v-bind:isPreview="true" :key="questionDialogKey"></component>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-main>
</template>

<style scoped>
.v-input__icon--append .v-icon {
	color: #221551;
}
.v-input__icon--append-outer .v-icon {
	background-color: #221551 !important;
	color: white !important;
	padding: 5px;
}
.v-input__icon--append-outer .v-icon.primary--text {
	background-color: #221551 !important;
	color: white !important;
	padding: 5px;
}
.v-input__icon--prepend .mdi-check-circle-outline {
	color: green;
}
.v-input__icon--prepend .mdi-close-circle-outline {
	color: red;
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import axios from "axios";
import pageStart from "./trainingModulePageStart";
import pageStart2 from "./trainingModulePageStart2";
import pageStart3 from "./trainingModulePageStart3";
import pageInformationImage1 from "./trainingModulePageInformationImage1";
import pageInformationImage2 from "./trainingModulePageInformationImage2";
import pageInformationImage3 from "./trainingModulePageInformationImage3";
import pageInformationText1 from "./trainingModulePageInformationText1";
import pageInformationText2 from "./trainingModulePageInformationText2";
import pageInformationVideo from "./trainingModulePageInformationVideo";
import pageMultipleChoice from "./trainingModulePageMultipleChoice.vue";
import pageShortAnswer from "./trainingModulePageShortAnswer.vue";
import pageFreeAnswer from "./trainingModulePageFreeAnswer.vue";
import formBuilder from "./trainingModuleFormBuilder.vue";

export default {
	props: ["moduleId", "pageId"],
	components: {
		// page-{page.type}
		"page-start": pageStart,
		"page-start-1": pageStart, //discrepancy between old data intentional duplicate, do not remove
		"page-start-2": pageStart2,
		"page-start-3": pageStart3,
		"page-info-image-1": pageInformationImage1,
		"page-info-image-2": pageInformationImage1,
		"page-info-image-3": pageInformationImage2,
		"page-info-image-4": pageInformationImage2,
		"page-info-image-5": pageInformationImage3,
		"page-info-text-1": pageInformationText1,
		"page-info-text-2": pageInformationText2,
		"page-info-video-1": pageInformationVideo,
		"page-info-video-2": pageInformationVideo,
		"page-multiple-choice-1": pageMultipleChoice,
		"page-multiple-choice-2": pageMultipleChoice,
		"page-short-answer": pageShortAnswer,
		"page-free-answer": pageFreeAnswer,
		"form-builder": formBuilder
	},
	data: function() {
		return {
			page: {
				valueMap: {
					headerTitle: "",
					headerBackgroundColor: {
						// grey
						hexa: '#424242'
					},
					headerIconColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					headerIconBackgroundColor: {
						// purple
						hexa: '#221551'
					},
					bodySummary: "",
					bodyTip: "",
					bodyNote: "",
					bodyIconTextColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					bodyIconBackgroundColor: {
						// purple
						hexa: '#440099'
					},
					bodyQuestionMultipleChoice: {},
					bodyQuestionShortAnswer: {},
					footerHasBackButton: false,
					footerButtonConfig: ""
				}
			},
			pageTypeStaticData: [
				// start and start 1 due to discrepancy data
				{ 
					type: "start", title: "Start Page 1", srcImage: "/template/anz-training-start.jpg",
					fields: ['header-title', 'header-icon-title', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary']
				},
				{ 
					type: "start-1", title: "Start Page 1", srcImage: "/template/anz-training-start.jpg",
					fields: ['header-title', 'header-icon-title', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary']
				},
				{ 
					type: "start-2", title: "Start Page 2", srcImage: "/template/anz-training-start-2.jpg",
					fields: ['header-title', 'header-icon-title', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary', 'body-image']
				},
				{
					type: "start-3", title: "Start Page 3", srcImage: "/template/anz-training-start-3.jpg",
					fields: ['header-title', 'body-summary']
				},
				{ 
					type: "info-image-1", title: "Information Image 1 Page", srcImage: "/template/anz-training-info-image-1.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary', 'body-image', 'footer-button-config']
				},
				{ 
					type: "info-image-2", title: "Information Image 2 Page", srcImage: "/template/anz-training-info-image-2.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary', 'body-image', 'footer-button-config']
				},
				{ 
					type: "info-image-3", title: "Information Image 3 Page", srcImage: "/template/anz-training-info-image-3.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary', 'body-image', 'footer-button-config']
				},
				{ 
					type: "info-image-4", title: "Information Image 4 Page", srcImage: "/template/anz-training-info-image-4.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary', 'body-image', 'footer-button-config']
				},
				{ 
					type: "info-image-5", title: "Information Image Full Page", srcImage: "/template/anz-training-info-image-5.jpg",
					fields: ['header-title', 'body-image']
				},
				{ 
					type: "info-video-1", title: "Information Video 1 Page", srcImage: "/template/anz-training-info-video-1.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-video', 'footer-button-config']
				},
				{ 
					type: "info-video-2", title: "Information Video 2 Page", srcImage: "/template/anz-training-info-video-2.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary', 'body-video', 'footer-button-config']
				},
				{ 
					type: "info-text-1", title: "Information Text 1 Page", srcImage: "/template/anz-training-info-text-1.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-summary', 'body-tip', 'body-note', 'footer-button-config']
				},
				{ 
					type: "info-text-2", title: "Information Text Page 2", srcImage: "/template/anz-training-info-text-2.jpg",
					fields: ['header-title', 'body-summary', 'body-tip', 'body-note', 'footer-button-config']
				},
				{ 
					type: "multiple-choice-1", title: "Multiple Choice 1 Page", srcImage: "/template/anz-training-question-multiple-choice-1.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-question-multiple-choice', 'body-video' , 'footer-button-config']
				},
				{ 
					type: "multiple-choice-2", title: "Multiple Choice 2 Page", srcImage: "/template/anz-training-question-multiple-choice-2.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-color', 'header-icon-background-color', 'body-question-multiple-choice', 'body-video' , 'footer-button-config']
				},
				{ 
					type: "short-answer", title: "Short Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-background-color', 'body-question-short-answer', 'body-video' , 'footer-button-config']
				},
				{ 
					type: "free-answer", title: "Free Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg",
					fields: ['header-title', 'header-background-color', 'header-icon', 'header-icon-background-color', 'body-question-free-answer', 'body-video', 'footer-button-config']
				},
			],
			pageEdit: {},
			dialogPreview: false,
			questionDialogKey: 1,
			isBackgroundImage: false,
			attachments: {}
		};
	},
	computed: {
		fields() {
			for (var i in this.pageTypeStaticData) {
				if (this.page.type == this.pageTypeStaticData[i].type) {
					return this.pageTypeStaticData[i].fields;
				}
			}
			return [];
		},
		isLoaded() {
			return !this.$root.isBlank(this.page.type);
		},
		pageClone() {
			return this.$root.clone(this.page);
		}
	},
	mounted: function() {
		this.findPage();
	},
	methods: {
		refresh() {
			this.$forceUpdate();
		},
		findPage() {
			axios
				.get("/api/page?id=" + this.pageId)
				.then(response => {
					var defaultValueMap = this.$root.clone(this.page.valueMap);
					this.page = response.data;
					if (this.$root.isBlank(this.page.valueMap)) {
						this.page.valueMap = defaultValueMap;
					}
				})
				.catch(() => {});
		},
		save() {
			this.$root.clean(this.page);
			let formData = new FormData();
			formData.append("page", JSON.stringify(this.page));
			if (this.attachments.attachment1) {
				formData.append("attachment1", this.attachments.attachment1);
			}
			if (this.attachments.attachment2) {
				formData.append("attachment2", this.attachments.attachment2);
			}
			axios
				.post("/api/page/edit?id=" + this.pageId, formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(response => {
					this.closePageConfiguration();
				})
				.catch(() => {});
		},
		showPreview(item) {
			if (String(item.type).includes("multiple-choice") || String(item.type).includes("short-answer")) {
				this.questionDialogKey++;
			}
			this.pageEdit = item;
			this.dialogPreview = true;
		},
		closePreview() {
			this.pageEdit = {};
			this.dialogPreview = false;
		},
		closePageConfiguration() {
			this.$router.push('/administration/trainingModule/' + this.moduleId + '/configuration');
		}
	}
};
</script>
