<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-card width="100%" min-height="416" color="transparent-card" rounded="0" :elevation="0">
					<v-card-title class="pa-0 ma-0" :style="{ backgroundColor: parsedHeaderBackgroundColor, color: 'white', minHeight: '65px', height: 'auto' }">
						<div v-html="page.valueMap.headerTitle" class="pt-3 pl-10 mr-12 pr-12 text-wrap"></div>
						<!-- Logo -->
						<v-avatar v-if="$root.isBlank(page.valueMap.headerIcon)" :color="parsedHeaderIconBackgroundColor" size="73" class="mt-15" style="position: absolute; right: 30px;">
							<v-icon :color="parsedHeaderIconColor" size="33" class="duo-icon-help"></v-icon>
						</v-avatar>
						<v-img v-else :src="$base + '/asset/file/' + page.valueMap.headerIcon" width="60" style="position: absolute; right: 30px; margin-top: -31px" />
					</v-card-title>

					<v-card-text class="ma-0 pa-0">
						<v-form v-model="validForm" ref="form" lazy-validation>
							<v-container class="d-flex align-center" style="height: auto">
								<v-row class="px-12 py-0">
									<v-col v-if="page.valueMap.bodyVideo && page.valueMap.bodyVideo.length > 0" class="d-flex justify-center" cols="12">
										<iframe v-if="page.valueMap.bodyVideo && (page.valueMap.bodyVideo.startsWith('http://') || page.valueMap.bodyVideo.startsWith('https://'))" width="640" height="360" :src="page.valueMap.bodyVideo" title="Video" frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowfullscreen />
									</v-col>
									<v-col v-if="page.valueMap.bodyQuestionMultipleChoice.questionText" cols="12" class="d-flex justify-left align-center text-wrap">
										<span v-html="page.valueMap.bodyQuestionMultipleChoice.questionText" style="font-size: 17px; color:#2D2926"></span>
									</v-col>
									<v-item-group class="d-flex col-12 justify-space-between flex-wrap" v-model="optionSelected" :multiple="isMultipleAnswer">
										<div v-for="option in page.valueMap.bodyQuestionMultipleChoice.optionList" :key="option.id" :class="'d-flex align-center px-0 py-2 ' + columnOption">
											<v-item disabled v-slot="{ active, toggle }">
												<v-card
													class="d-flex align-center duo-custom"
													style="width: 100%; border-radius: 4px; padding-left: 26px; background-color: rgba(0,0,0,0) !important; color: #2D2926;"
													:style="active ? (isCorrect ? 'border: 2px solid #221551;' : 'border: 2px solid #C73C09;') : 'border: 2px solid #C6C6C6;'"
													width="100%"
													height="100%"
													min-height="46"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, option.id, option.feedback)"
												>
													<v-scroll-y-transition>
														<span v-if="!active" style="font-size: 17px;" v-html="option.label"></span>
														<span v-else style="font-size: 17px; width: 100%;">
															<v-row style="width: 100%;" class="full-width py-2">
																<v-col cols="auto">
																	<b v-if="isCorrect" style="color: #440099;">That's right!</b>
																	<b v-else style="color: #C73C09;">Incorrect.</b>
																</v-col>
																<v-col>
																	<div class="duo-custom" v-html="answerFeedback"></div>
																</v-col>
															</v-row>
														</span>
													</v-scroll-y-transition>
												</v-card>
											</v-item>
										</div>
									</v-item-group>
								</v-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" class="d-flex justify-end">
				<v-row class="d-flex flex-wrap">
					<v-col cols="12" class="d-flex justify-end pr-0">
						<v-btn :disabled="!validForm || !isSubmitted" height="50" width="193" elevation="0" outlined color="duoSecondary" class="white--text ml-auto text-transform-none" :right="true" @click="nextPage()">Next</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		parsedHeaderBackgroundColor() {
			if (this.page.valueMap.headerBackgroundColor) {
				return this.page.valueMap.headerBackgroundColor.hexa;
			} else {
				return "#2D2926";
			}
		},
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				return "#440099";
			}
		},
		columnOption() {
			if (this.page.type == "multiple-choice-1") {
				// horizontal
				return "col-3";
			} else if (this.page.type == "multiple-choice-2") {
				// vertical
				return "col-12";
			} else {
				// horizontal
				return "col-2";
			}
		},
		isMultipleAnswer() {
			return this.getActualCorrectId().length > 1;
		}
	},
	data: function () {
		return {
			validForm: true,
			isSubmitted: false,
			isSelected: false,
			isCorrect: null,
			answerFeedback: null,
			responseOptionId: [],
			optionSelected: null
		};
	},
	methods: {
		validateAnswer(responseOptionId) {
			this.isSubmitted = true;
			this.isSelected = true;
			var actualCorrectId = this.getActualCorrectId();

			for (var j in responseOptionId) {
				this.isCorrect = actualCorrectId.find(id => id === responseOptionId[j]) ? true : false;
				if (this.isCorrect) {
					continue;
				} else {
					break;
				}
			}

			if (actualCorrectId.length !== responseOptionId.length) {
				this.isCorrect = false;
			}
		},
		fetchAnswer(response) {
			let pageScore = response.data.map.pageScore.find(item => parseInt(item.pageId) === this.page.id);
			let options = this.page.valueMap.bodyQuestionMultipleChoice.optionList;
			let isMultipleChoice = options.filter(option => option.isCorrectAnswer == true).length > 1;
			let scoreAnswer = JSON.parse(pageScore.answer);

			if (!isMultipleChoice) {
				this.optionSelected = options.findIndex(option => option.id == scoreAnswer[0]);
			} else {
				let optionAnswer = [];
				for (const answerId of scoreAnswer) {
					optionAnswer.push(options.findIndex(option => option.id === answerId));
				}
				this.optionSelected = optionAnswer;
			}
			
			this.validateAnswer(JSON.parse(pageScore.answer));
		},
		getActualCorrectId() {
			var actualCorrectId = [];
			for (var i in this.page.valueMap.bodyQuestionMultipleChoice.optionList) {
				if (this.page.valueMap.bodyQuestionMultipleChoice.optionList[i].isCorrectAnswer) {
					actualCorrectId.push(this.page.valueMap.bodyQuestionMultipleChoice.optionList[i].id);
				}
			}
			return actualCorrectId;
		},
		submit() {
			if (this.$refs.form.validate()) {
				this.validateAnswer(this.responseOptionId);

				if (!this.isPreview) {
					this.page.valueMap.responseOptionId = this.responseOptionId;
					// this.save(correctOption);
					this.$emit("submit");
				}
			}
		},
		save() {
			// TODO
			// API Check + Save User Answer
			axios
				.post("/system/page/edit", { page: this.page })
				.then(() => {})
				.catch(() => {});
		},
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.validForm = true;
				this.isSubmitted = false;
				this.isSelected = false;
				this.isCorrect = null;
				this.answerFeedback = null;
				this.responseOptionId = [];
				this.optionSelected = null;
				this.$emit("nextPage");
			}
		},
		onClickOption(toggle, active, optionId, feedback) {
			if (!this.isSelected) {
				toggle();
				if (active) {
					// only one answer
					if (this.getActualCorrectId().length === 1) {
						this.responseOptionId = [];
						this.responseOptionId.push(optionId);
					} else {
						if (this.responseOptionId.indexOf(optionId) === -1) {
							this.responseOptionId.push(optionId);
						}
					}
				} else {
					this.responseOptionId =	this.responseOptionId.filter(option => option !== optionId);
				}
				this.isSelected = !this.$root.isBlank(this.responseOptionId) ? true : false;
				this.answerFeedback = feedback;
				this.submit()
			}
		}
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.form-group {
	height: 80vh;
}
.radio-question .v-label {
	font-size: 20px !important;
}
.radio-question .error--text {
	font-size: 20px !important;
}
.v-application .title {
	font-size: 26px !important;
	font-weight: 600;
	letter-spacing: 1px !important;
}
.correct-answer {
	color: green;
	font-size: 20px;
	font-weight: bold;
}
.wrong-answer {
	color: red;
	font-size: 20px;
	font-weight: bold;
}
.question-answer {
	border-left: 2px solid rgba(224, 224, 224, 0.5);
}
</style>