<template>
	<v-container class="d-flex px-6 pb-6 pt-6 align-center justify-center">
		<v-row>
			<v-col cols="12">
				<v-row class="px-3">
					<v-col cols="12">
						<p><span style="font-size: 21px;"><strong>Question</strong></span></p>
						<v-text-editor v-model="local.questionText" :api-key="$tinyMceKey" :init="initEditor" />
					</v-col>
				</v-row>

				<template v-if="isMultipleChoice()" >		
					<div class="px-3 mt-5">
					<p><span style="font-size: 21px;"><strong>Multiple Option</strong></span></p>
					</div>		
					<draggable v-bind="dragOptions" v-model="local.optionList">
						<v-row class="d-flex justify-space-between px-3 mt-3" no-gutters v-for="(option, index) in local.optionList" :key="option.id" :class="option.isCorrectAnswer ? 'transparent-green' : ''" style="cursor: move">
							<v-col cols="10" class="d-flex align-center">
								<v-row>
									<v-col cols="1" class="d-flex align-center">
										<template v-if="option.isCorrectAnswer">
											<v-icon color="green" @click="changeCorrectAnswer(option)">mdi-radiobox-marked</v-icon>
										</template>
										<template v-else>
											<v-icon color="grey" @click="changeCorrectAnswer(option)">mdi-radiobox-blank</v-icon>
										</template>
									</v-col>
									<v-col cols="10" class="d-flex flex-wrap align-center">
										<span class="mb-2" style="font-size: 17px;" ><strong>Option {{index + 1}}</strong></span>
										<v-text-editor v-model="option.label" :api-key="$tinyMceKey" :init="initEditor" />
										<span class="my-2" style="font-size: 17px;" ><strong>Feedback answer</strong></span>
										<v-text-editor v-model="option.feedback" :api-key="$tinyMceKey" :init="initEditor" />
									</v-col>
									<v-col cols="1" class="d-flex align-center">
										<template v-if="option.isCorrectAnswer">
											<v-icon color="green">mdi-check-circle-outline</v-icon>
										</template>
										<template v-else>
											<v-icon color="red" size="20" class="pt-1" @click="deleteOption(option.id)">icon-duo-trash</v-icon>
										</template>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="1" class="d-flex align-center">
								<v-text-field disabled hide-spin-buttons v-model="option.points" label="Points" class="rounded-0" single-line type="number" hide-details outlined></v-text-field>
							</v-col>
							<v-spacer></v-spacer>
							<v-col cols="auto" class="d-flex justify-end align-center">
								<div class="pr-2">
									<v-icon color="grey">mdi-drag</v-icon>
								</div>
							</v-col>
							<v-col cols="12">
								<v-divider class="my-4"></v-divider>
							</v-col>
						</v-row>
					</draggable>
					<v-row>
						<v-col cols="12" class="d-flex justify-end" style="margin-left: 6px">
							<v-btn icon fab color="green" x-small @click="addOption()">
								<v-icon>icon-duo-plus</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</template>

				<template v-if="isShortAnswer()">
					<v-row class="px-3" no-gutters>
						<v-col cols="12" xl="10" lg="9" md="7">
							<v-text-field
								:hint="local.regex ? 'Enter pattern regex' : 'Enter answer'"
								:value="local.correctAnswerText"
								label="Correct Answer"
								@input="update('correctAnswerText', $event)"
								:rules="[v => !!v || 'Short answer is required']"
							></v-text-field>
						</v-col>
						<v-col cols="12" xl="1" lg="1" md="2" sm="6">
							<v-checkbox v-model="local.regex" @change="update('regex', $event)" label="RegEx"></v-checkbox>
						</v-col>
						<v-col cols="12" xl="1" lg="2" md="3" sm="6">
							<v-checkbox v-model="local.caseSensitive" @change="update('caseSensitive', $event)" label="Case Sensitive"></v-checkbox>
						</v-col>
						<v-col cols="12" xl="10" lg="9" md="7">
							<v-text-field
								:value="local.correctAnswerReason"
								label="Correct Answer's reason (optional)"
								@input="update('correctAnswerReason', $event)"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
import Editor from "@tinymce/tinymce-vue";
import draggable from "vuedraggable";

export default {
	components: {
		"v-text-editor": Editor,
		draggable
	},
	props: {
		value: Object,
		type: {
			type: String,
			required: true
		}
	},
	computed: {
		local() {
			if (this.$root.isBlank(this.value)) {
				// if blank, then assign default 'question' object to binding object 'value' with 'v-model'
				this.$emit("input", this.$root.clone(this.question));
			}
			return this.value;
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		}
	},
	data: function () {
		return {
			// default template if props 'value' null
			question: {
				questionText: "",
				correctAnswerText: "",
				regex: false,
				caseSensitive: false,
				optionList: [
					{
						id: 1,
						label: "",
						isCorrectAnswer: false,
						points: 0
					}
				],
			},
			initEditor: {
					height: 150,
					width: '100%',
					selector: '',
					menubar: false,
					plugins: ["code", "paste", "table", "lists", "link", "textcolor" ],
					paste_as_text: true,
					fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px",
					toolbar:
							"code undo redo | fontsizeselect | bold italic underline forecolor backcolor | table | \
					alignleft aligncenter alignright alignjustify | \
					bullist numlist outdent indent | link | removeformat | pastetext | help"
			}
		};
	},
	methods: {
		update(propName, propValue) {
			var newValue = { [propName]: propValue };
			var newQuestionObject = this.$root.copyWith(newValue, this.local);
			// behind the scene of 'v-model', return new question object
			this.$emit("input", newQuestionObject);
		},
		addOption() {
			var idList = [];
			var newId = 0;
			var maxLength = this.type === "multiple-choice-1" ? 5 : this.local.optionList.length + 1;
			// collect id from optionList
			for (var i = 0; i < this.local.optionList.length; i++) {
				var id = this.local.optionList[i].id;
				if (id !== null && id !== undefined) {
					idList.push(this.local.optionList[i].id);
				}
			}
			// assign new id if not used, from 1 <= maximum option
			for (var j = 1; j <= maxLength; j++) {
				if (!idList.includes(j)) {
					newId = j;
					break;
				}
			}
			if (this.local.optionList.length < maxLength) {
				this.local.optionList.push({
					id: newId,
					label: null,
					isCorrectAnswer: false,
					points: 0
				});
			}
		},
		deleteOption(id) {
			this.local.optionList = this.local.optionList.filter(option => option.id !== id);
		},
		changeCorrectAnswer(option) {
			option.isCorrectAnswer = !option.isCorrectAnswer;
			option.points = option.isCorrectAnswer ? 1 : 0;
		},
		isMultipleChoice() {
			return String(this.type).includes("multiple-choice");
		},
		isShortAnswer() {
			return String(this.type).includes("short-answer");
		}
	}
};
</script>

<style scoped>
.text-transform-none.v-btn {
	text-transform: none !important;
}
.transparent-green {
	background-color: rgba(242, 246, 233, 1) !important;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
</style>